if( window.MercadoPagoComponent){
    class EcommerceMercadoPagoComponent extends window.MercadoPagoComponent {

        static getWatch() {
            return {
                "cardnumber": function () {
                    this.guessingPaymentMethod();
                },
                "docTypes":function(newValue,oldValue){
                    console.log(newValue,oldValue);
                }
            };
        }

        static getTemplate() {
            return `
                <div id="ecommerce-mercadopago" class="row col-12">
                    <div class="card">
                        <div class="card-body card-content">
                            <div class="credit-card-box">
                                <div class="flip">
                                    <div class="front">
                                        <div class="card-logo" :class="getTemplatePaymentMethod()"></div>
                                        <div class="number">{{cardnumber}}</div>
                                        <div class="card-holder">
                                            <label>Nombre del Portador</label>
                                            <div>{{form.holderName}}</div>
                                        </div>
                                        <div class="card-expiration-date">
                                            <label>Vencimiento</label>
                                            <div>{{form.expirationMonth}}/{{form.expirationYear}}</div>
                                        </div>
                                    </div>
                                    <div class="back">
                                        <div class="strip"></div>
                                        <div class="card-logo" :class="[paymentMethodId]"></div>
                                        <div class="ccv">
                                            <label>CCV</label>
                                            <div>{{form.ccv}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form id="pay">
                                <div class="row ">
                                    <div class="col-12 col-lg-6">
                                        <label for="cardNumber" class="col-form-label">Número de Tarjeta</label>
                                        <input v-model="cardnumber" class="form-control" v-on:keyup="validate()" required type="text" data-inputmask="'mask': '9999 9999 9999 9999'" id="cardNumber" maxlength="20" data-checkout="cardNumber" placeholder="XXXX XXXX XXXX XXXX" onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off />
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <label for="cardholderName" class="col-form-label">Nombre y apellido</label>
                                        <input class="form-control" type="text" id="cardholderName" v-model="form.holderName" v-on:keyup="validate()" required data-checkout="cardholderName" placeholder="" />
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <label for="cardExpirationMonth" class="col-form-label">Mes de exp.</label>
                                        <input class="form-control" type="text"  maxlength="2" id="cardExpirationMonth" v-model="form.expirationMonth" pattern="[0-9]{2}" title="Dos números del mes de expiración" v-on:keyup="validate()" required data-checkout="cardExpirationMonth" placeholder="xx" onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off />
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <label for="cardExpirationYear" class="col-form-label">Año de exp.</label>
                                        <input class="form-control" type="text" maxlength="4" id="cardExpirationYear" v-model="form.expirationYear" pattern="[0-9]{4}" title="Cuatro números del año de expiración" v-on:keyup="validate()" required data-checkout="cardExpirationYear" placeholder="xxxx" onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off />
                                    </div>
                                    <div class="col-12 col-lg-4">
                                       <label for="securityCode" class="col-form-label">Código de seguridad</label>
                                        <input class="form-control" type="text" maxlength="4" id="securityCode" v-model="form.ccv" pattern="[0-9]{3,4}" title="Código de tres números al reverso de la tarjeta" v-on:keyup="validate()" required data-checkout="securityCode" placeholder="xxx" />
                                    </div>
                                    <template v-if="docTypes.length>0">
                                        <div class="col-12 col-lg-6">
                                            <label for="docType" class="col-form-label">Tipo de doc.</label>
                                            <select class="custom-select" type="text" id="docType" v-on:keyup="validate()" required data-checkout="docType" >
                                              <template v-for="docType in docTypes">
                                                <option :value="docType.id" :selected="docType.id=='DNI'" >{{docType.name}}</option>
                                             </template>   
                                            </select>
                                        </div>
                                    </template>
                                    <div class="col-12 col-lg-6">
                                        <label for="docNumber" class="col-form-label">Documento</label>
                                        <input class="form-control" type="text" id="docNumber" v-on:keyup="validate()" required data-checkout="docNumber" placeholder="" />
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <label for="email" class="col-form-label">Correo electrónico</label>
                                        <input class="form-control" id="email" name="email" v-model="email" v-on:keyup="validate()" required type="email" placeholder="email@address.com"/>
                                    </div>
                                    <div class="row col-12 my-3 mt-md-3 mb-md-0 justify-content-center">
                                        <div class="row col-12 payment-info text-center">
                                            <div class="col-md-6 offset-md-3">
                                                <p class="amount valign-wrapper">$ {{parseFloat(amount).toFixed(2)}}</p>
                                            </div>
                                             <div class="col-12">
                                                <button class="col-12 col-md-4 btn submit-btn" @click="doPay" :disabled="!isValid || disableBtn"><i class="fa fa-lock"></i> Pagar</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="mercadopago-logo">
                                        <img class="responsive-img" src="/static/mercadopago/img/mercado-pago.png" />
                                    </div>
                                </div>
                                <input v-model="paymentMethodId" type="hidden" name="paymentMethodId" />
                            </form>
                        </div>
                    </div>
                </div>
        `;
        }

    }
    Vue.component('mercadopagoCustom', EcommerceMercadoPagoComponent.getComponent());
    window.EcommerceMercadoPagoComponent = EcommerceMercadoPagoComponent
}
